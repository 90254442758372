@import 'components/global.scss';

.app {
  padding: $scale2;
  min-height: 100%;
  background-color: $c-bg;

  display: flex;
  flex-direction: column;
  // height: 100%;

  @media (min-width: $mobile) {
    padding-left: $scale7 + $scale1;
  }
}
