@import 'components/global.scss';

.subnav {
  position: relative;
  padding: $scale1 $scale4 0;
  margin: $scale2 * -1 $scale2 * -1 $scale2 $scale1 * -2;
  background: #fff;
  border-top: 1px solid $c-border;
  z-index: 30;
}

.item {
  color: $c-text;
  display: inline-block;
  padding-bottom: $scale;
  margin-right: $scale2;
  text-decoration: none;

  &:hover {
    border-bottom: 2px solid lighten($c-blue, 15%);
  }
  &.active,
  &:focus {
    border-bottom: 2px solid $c-blue;
  }
}
